import React from "react"
import {injectIntl} from "gatsby-plugin-intl"
import Button from "../common/button";
import styled from "styled-components"
import SVGPhone from "../../images/svgs/llamar.svg";
import campaigns from "../../../static/ddi.json"


const CallButtonSupport = ({children,location,intl,type, uselink=false,}) => {
  const supportPhone = "930 477 073";

    return (
      <>
      {
        uselink === false && (
        <Button id="gta_click_call"
                onClick={()=>{
                    window.dataLayer.push({'event': 'AttClickCall'});
                    window.open(`tel:${supportPhone}`);
                    }}
                rounded
                btnType={type}
                fullwidth={true}
        >
            {children}
        </Button>)}
        {uselink === true && (
          <CallLink>
            <a onClick={() => {
              window.dataLayer.push({'event': 'AttClickCall'});
              window.open(`tel:${supportPhone}`);
            }}>
              {children}
            </a>
          </CallLink>
        )}
      </>

    )
}

const CallLink = styled.div`
  text-decoration: underline;
  ${({ theme }) => theme.font_size.regular};
  color: ${({ theme }) => theme.color.purple};
  font-family: ${({theme}) => theme.font.bold};
`
export default injectIntl(CallButtonSupport)
